import React from 'react'

class Merch extends React.Component {
    render() {
        return (
            <div className="geerStoreMerch">

            </div>
        );
    }
}

export default Merch