import React from 'react';

class HistoryAndTraditions extends React.Component {
    render() {
        return (
            <div className="aboutUsHistoryAndTraditions">
            
            </div>
        );
    }
}

export default HistoryAndTraditions;