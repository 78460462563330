import React from 'react';

class SeniorExecMeetings extends React.Component {
    render(){
        return(
            <div className="governanceSeniorExecMeetings">

            </div>
        );
    }
}

export default SeniorExecMeetings;