import React from 'react';

class BylawsAndPolicy extends React.Component {
    render(){
        return(
            <div className="governanceBylawsAndPolicy">

            </div>
        );
    }
}

export default BylawsAndPolicy;