import React from 'react';

class BoDMeetings extends React.Component {
    render() {
        return (
            <div className="governanceBoDMeetings">

            </div>
        );
    }
}

export default BoDMeetings;