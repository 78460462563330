import React from 'react';

class HealthAndWellness extends React.Component {
    render() {
        return (
            <div className="servicesHealthAndWellness">

            </div>
        );
    }
}

export default HealthAndWellness;