import React from 'react';

class OurTeam extends React.Component {
    render() {
        return (
            <div className="aboutUsOurTeam">

            </div>
        );
    }
}

export default OurTeam;