import React from 'react';

class Resources extends React.Component {
    render() {
        return (
            <div className="servicesResources">

            </div>
        );
    }
}

export default Resources;