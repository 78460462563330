import React from 'react';

class ServicesScreen extends React.Component {
    render() {
        return (
            <div className="servicesScreen">

            </div>
        );
    }
}

export default ServicesScreen;