import React from 'react';

class EventsScreen extends React.Component {
    render() {
        return (
            <div className="eventsScreen">

            </div>
        );
    }
}

export default EventsScreen;