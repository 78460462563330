import React from 'react';

class OurAffiliates extends React.Component {
    render() {
        return (
            <div className="aboutUsOurAffiliates">
            
            </div>
        );
    }
}

export default OurAffiliates;