import React from 'react';

class EventsCalendar extends React.Component {
    render(){
        return(
            <div className="eventsEventsCalendar">

            </div>
        );
    }
}

export default EventsCalendar;