import React from 'react';

class Pass extends React.Component {
    render() {
        return (
            <div className="servicesPass">

            </div>
        );
    }
}

export default Pass;