import React from 'react'

class LockerRentals extends React.Component {
    render() {
        return (
            <div className="geerStoreLockerRentals">

            </div>
        );
    }
}

export default LockerRentals