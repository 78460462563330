import React from 'react';

class FirstYearSurvialGuide extends React.Component {
    render() {
        return (
            <div className="servicesFirstYearSurvialGuide">

            </div>
        );
    }
}

export default FirstYearSurvialGuide;