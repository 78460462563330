import React from 'react';

class DisciplineClubs extends React.Component {
    render() {
        return (
            <div className="getInvolvedDisciplineClubs">

            </div>
        );
    }
}

export default DisciplineClubs;