import React from 'react';

class FirstYearEnggClub extends React.Component {
    render() {
        return (
            <div className="getInvolvedFirstYearEnggClub">

            </div>
        );
    }
}

export default FirstYearEnggClub;