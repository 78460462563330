import React from 'react';

class Confrences extends React.Component {
    render(){
        return(
            <div className="eventsConfrences">

            </div>
        );
    }
}

export default Confrences;