import React from 'react';

class GetInvolvedScreen extends React.Component {
    render() {
        return (
            <div className="getInvolvedScreen">

            </div>
        );
    }
}

export default GetInvolvedScreen;