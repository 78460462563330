import React from 'react';

class GovernanceScreen extends React.Component {
    render() {
        return (
            <div className="governanceScreen">

            </div>
        );
    }
}

export default GovernanceScreen;